<script>
import faqService from '@/services/faq.service'
import Faq from '@/data/models/Faq'
import EditLayout from '@/layout/EditLayout'

export default {
  name: 'SingleFaq',
  components: {
    EditLayout
  },
  data() {
    return {
      items: [],
      item: new Faq,
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    },
    isNew() {
      return this.id === 'new'
    },
  },  
  mounted() {
    !this.isNew && this._get()
    this._getAll()
  },
  methods: {
    _getAll() {
      faqService.getAll()
        .then(res => {
          this.items = res.data
          this.item.position = res.data.length + 1
        })
    },
    _get() {
      faqService.getById({ id: this.id })
        .then(res => {
          this.item = res.data
        })
    },
    create() {
      faqService.create({ data: this.item })
        .then(response => {
          this.$notify({
            message: this.$t('m.itemSuccessfullyAdded', { item: this.$tc('faq') }),
            icon: "far fa-bell",
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success',
          })
          this.$router.push({ name: 'faq' })
        })
    },
    edit() {
      faqService.edit({ data: this.item })
        .then(_res => {
          this.$notify({
            message: this.$t('m.itemSuccessfullySaved', { item: this.$tc('faq') }),
            icon: "far fa-bell",
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success',
          })
        })
    },
  }
}
</script>

<template>
  <EditLayout 
    :item="item"
    :submit-button-text="isNew ? $t('create') : $t('save')"
    @submit="isNew ? create() : edit()"
  >
    <div slot="sidebar-middle">
      <BaseInput
        v-model="item.position"
        :label="$t('position')"
        disabled
      />
    </div>
  </EditLayout>
</template>

<style lang="sass" scoped>
@import '~@/assets/sass/design.sass'
</style>